import React, { useEffect } from "react";

import { Theme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { useAppDispatch, useAppSelector } from "./app/hooks";
import { setWidth } from "./appSlice";

import Router from "./config/Router";

import "./App.css";
import themes from "./config/themes";

import FeatureDrawer from "./features/feature-drawer/FeatureDrawer";
import CampusModal from "./features/modal/CampusModal";

export default function App() {
  const activeTheme = useAppSelector((state) => state.featureDrawer.activeTheme);
  const theme: Theme = themes[activeTheme];

  const dispatch = useAppDispatch();

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(setWidth(window.innerWidth));
    });
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
      <FeatureDrawer />
      <CampusModal />
    </ThemeProvider>
  );
}
