import { Modal, Box, Typography } from "@material-ui/core";
import React from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const getOs = () => {
  const os = ["iPhone", "Windows", "Macintosh"];
  return os.find((v) => (global as any).window?.navigator.userAgent.indexOf(v) >= 0);
};

const CampusModal = () => {
  const showPopup = getOs() === "iPhone" && new Date() <= new Date("2023-09-11");

  const [open, setOpen] = React.useState(showPopup);
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Download the Daily Princetonian App
        </Typography>
        <Typography id="modal-modal-description" style={{ marginTop: 2 }}>
          The Daily Princetonian is now available as a mobile app on iOS.
        </Typography>
        <Typography id="modal-modal-description" style={{ marginTop: 2 }}>
          Have both Campus and stories from the Princetonian at your fingertips. Don&apos;t miss{" "}
          <strong>app-exclusive</strong> features such as <strong>town discounts</strong> and our{" "}
          <strong>new Events page</strong>, where you can browse and register for upcoming events on campus.
        </Typography>
        <Box sx={{ width: "100%", marginTop: 20, display: "flex", justifyContent: "center" }}>
          <a
            href="https://apps.apple.com/us/app/the-daily-princetonian/id6461162604"
            target="_blank"
            rel="noreferrer"
            style={{ width: "200px", display: "inline-block" }}
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg"
              alt="Download on the App Store"
              style={{ width: "100%", height: "auto" }}
            />
          </a>
        </Box>
      </Box>
    </Modal>
  );
};

export default CampusModal;
