import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContentCardState, TopStories } from "../../app/types";

const initialState: ContentCardState = {
  topStories: {
    section: {
      slug: "",
      title: "",
      tags: [],
    },
    articles: [],
    date: Date.now(),
  },
  contentLoaded: false,
};

export const contentCardSlice = createSlice({
  name: "contentCard",
  initialState,
  reducers: {
    setTopStories: (state, action: PayloadAction<TopStories>) => {
      return { ...state, topStories: action.payload };
    },
    load: (state) => {
      return { ...state, contentLoaded: true };
    },
  },
});

export const { setTopStories, load } = contentCardSlice.actions;
export default contentCardSlice.reducer;
