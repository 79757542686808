import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Menu, MenusState } from "../../app/types";
import { getDefaultActiveMeal } from "./utils";

const initialState: MenusState = {
  menus: [],
  abbreviatedMenus: [],
  activeMeal: getDefaultActiveMeal(),
  activeDay: 0,
};

export const princeMenusSlice = createSlice({
  name: "menus",
  initialState,
  reducers: {
    setMenus: (state, action: PayloadAction<Menu[]>) => {
      return { ...state, menus: action.payload };
    },
    setAbbreviatedMenus: (state, action: PayloadAction<Menu[]>) => {
      return { ...state, abbreviatedMenus: action.payload };
    },
    setDay: (state, action: PayloadAction<number>) => {
      return { ...state, activeDay: action.payload };
    },
    incrementDay: (state) => {
      if (state.activeDay === 6) return { ...state, activeDay: 0 };
      return { ...state, activeDay: state.activeDay + 1 };
    },
    decrementDay: (state) => {
      if (state.activeDay === 0) return { ...state, activeDay: 6 };
      return { ...state, activeDay: state.activeDay - 1 };
    },
    setMeal: (state, action: PayloadAction<number>) => {
      return { ...state, activeMeal: action.payload };
    },
    incrementMeal: (state) => {
      return { ...state, activeMeal: state.activeMeal + 1 };
    },
    decrementMeal: (state) => {
      return { ...state, activeMeal: state.activeMeal - 1 };
    },
  },
});

export const {
  setMenus,
  setAbbreviatedMenus,
  setDay,
  incrementDay,
  decrementDay,
  setMeal,
  incrementMeal,
  decrementMeal,
} = princeMenusSlice.actions;
export default princeMenusSlice.reducer;
