import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Ad, AdCardState } from "../../app/types";

const initialState: AdCardState = {
  ads: [],
  streetAds: [],
};

export const adCardSlice = createSlice({
  name: "ad",
  initialState,
  reducers: {
    setAds: (state, action: PayloadAction<Ad[]>) => {
      return { ...state, ads: action.payload };
    },
    setStreetAds: (state, action: PayloadAction<Ad[]>) => {
      return { ...state, streetAds: action.payload };
    },
  },
});

export const { setAds, setStreetAds } = adCardSlice.actions;
export default adCardSlice.reducer;
