import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
    height: "100%",
  },
  listItemText: {
    fontFamily: "Barlow Condensed",
    fontWeight: 700,
    fontSize: 45,
    fontStyle: "italic",
  },
  option: {
    textDecoration: "none",
    color: "#fff",
  },
  menuButton: {
    position: "fixed",
    right: theme.spacing(1.5),
    top: theme.spacing(1.5),
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 6px",
    color: "#fff",
    [theme.breakpoints.down("xs")]: {
      scale: "75%",
    },
  },
  paperBackground: {
    backgroundColor: theme.customPalette.paperOpaque,
  },
  image: {
    maxWidth: 220,
  },
  princeWordmark: {
    fontFamily: "minion-pro",
    fontSize: "1.2rem",
    fontWeight: "bolder",
    color: "#fff",
  },
}));

export default useStyles;
