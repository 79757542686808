import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Device = "ios" | "android" | "none";

export interface AboutPageState {
  device: Device;
}

const initialState: AboutPageState = {
  device: "none",
};

export const aboutPageSlice = createSlice({
  name: "aboutPage",
  initialState,
  reducers: {
    changeDevice: (state: AboutPageState, action: PayloadAction<Device>) => {
      return {
        ...state,
        device: action.payload,
      };
    },
  },
});

export const { changeDevice } = aboutPageSlice.actions;
export default aboutPageSlice.reducer;
