import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EatingClub, ProspectAveState } from "../../app/types";
import { getDefaultActiveDay } from "./utils";

const initialState: ProspectAveState = {
  eatingClubs: [],
  activeDay: getDefaultActiveDay(),
};

export const prospectAveSlice = createSlice({
  name: "street",
  initialState,
  reducers: {
    setEatingClubs: (state, action: PayloadAction<EatingClub[]>) => {
      return {
        ...state,
        eatingClubs: action.payload,
      };
    },
    decrementDay: (state) => {
      return { ...state, activeDay: state.activeDay - 1 };
    },
    incrementDay: (state) => {
      return { ...state, activeDay: state.activeDay + 1 };
    },
    setDay: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        activeDay: action.payload,
      };
    },
  },
});

export const { setEatingClubs, decrementDay, incrementDay, setDay } = prospectAveSlice.actions;
export default prospectAveSlice.reducer;
