import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SportsState, SportType } from "../../app/types";

const initialState: SportsState = {
  selectedSport: undefined, // Show all sports by default
  numResults: 5, // default number of results to display
};

export const sportsSlice = createSlice({
  name: "sports",
  initialState,
  reducers: {
    setSelectedSport: (state, action: PayloadAction<SportType>) => {
      return { ...state, selectedSport: action.payload };
    },
    setNumResults: (state, action: PayloadAction<number>) => {
      return { ...state, numResults: action.payload };
    },
  },
});

export const { setSelectedSport, setNumResults } = sportsSlice.actions;
export default sportsSlice.reducer;
