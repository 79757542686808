import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Business, TownPageState } from "../../app/types";

const initialState: TownPageState = {
  businesses: [],
  selectedBusiness: undefined,
};

export const townPageSlice = createSlice({
  name: "town",
  initialState,
  reducers: {
    setBusinesses: (state, action: PayloadAction<Business[]>) => {
      return { ...state, businesses: action.payload };
    },
    setSelectedBusiness: (state, action: PayloadAction<Business | undefined>) => {
      return { ...state, selectedBusiness: action.payload };
    },
  },
});

export const { setBusinesses, setSelectedBusiness } = townPageSlice.actions;
export default townPageSlice.reducer;
