import React from "react";
import { Route, Routes } from "react-router-dom";

import LoadingView from "../components/loading/LoadingView";

const Home = React.lazy(() => import("../features/home/Home"));
const Menus = React.lazy(() => import("../features/menus/Menus"));
const Street = React.lazy(() => import("../features/street/Street"));
const Town = React.lazy(() => import("../features/town/Town"));
const Sports = React.lazy(() => import("../features/sports/Sports"));
const DiscountPage = React.lazy(() => import("../features/town/DiscountPage"));
const About = React.lazy(() => import("../features/about/About"));
const Events = React.lazy(() => import("../features/events/Events"));

interface RouteSchema {
  path: string;
  exact: boolean;
  component: JSX.Element;
}

const routes: RouteSchema[] = [
  { path: "/", exact: true, component: <Home /> },
  { path: "/home", exact: true, component: <Home /> },
  { path: "/menus", exact: false, component: <Menus /> },
  { path: "/street", exact: false, component: <Street /> },
  { path: "/town", exact: true, component: <Town /> },
  { path: "/sports", exact: false, component: <Sports /> },
  { path: "/town/:id/:year", exact: true, component: <DiscountPage /> },
  { path: "/about", exact: false, component: <About /> },
  { path: "/events", exact: false, component: <Events /> },
];

export default function Router() {
  return (
    <Routes>
      {routes.map((route: RouteSchema) => (
        <Route
          key={route.path}
          path={route.path}
          element={<React.Suspense fallback={<LoadingView />}>{route.component}</React.Suspense>}
        />
      ))}
    </Routes>
  );
}
