interface SocialMedia {
  name: string;
  icon: string;
  url: string;
}

const socialMedia: SocialMedia[] = [
  {
    name: "twitter",
    icon: "fab fa-twitter",
    url: "https://twitter.com/princetonian",
  },
  {
    name: "facebook",
    icon: "fab fa-facebook-f",
    url: "https://www.facebook.com/DailyPrincetonian",
  },
  {
    name: "instagram",
    icon: "fab fa-instagram",
    url: "https://www.instagram.com/dailyprincetonian",
  },
  {
    name: "tiktok",
    icon: "fab fa-tiktok",
    url: "https://vm.tiktok.com/ZMeNbh7Hc/",
  },
  {
    name: "spotify",
    icon: "fab fa-spotify",
    url: "https://open.spotify.com/show/5xeaopGvoY0teJn0atpNPM",
  },
  {
    name: "youtube",
    icon: "fab fa-youtube",
    url: "https://www.youtube.com/channel/UC6DZuLRHcb2Nf6bP3nna9VQ",
  },
];

export default socialMedia;
