import { createTheme as createMuiTheme, Theme } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createTheme" {
  interface Theme {
    customPalette: {
      background: string;
      paper: string;
      paperClosed: string;
      paperOpaque: string;
    };
  }
  interface ThemeOptions {
    customPalette?: {
      background?: string;
      paper?: string;
      paperClosed?: string;
      paperOpaque?: string;
    };
  }
}

const themes: { [key: string]: Theme } = {
  home: createMuiTheme({
    palette: {
      type: "dark",
      text: {
        secondary: "rgba(0, 0, 0, 0.9)",
      },
      background: {
        default: "rgb(250, 166, 87)",
      },
    },
    customPalette: {
      background: "linear-gradient(0deg, rgb(237, 179, 255) 5%, rgb(250, 166, 87) 100%)",
      paper: "rgb(237, 179, 255)",
      paperOpaque: "rgb(250, 166, 87)",
    },
  }),
  menus: createMuiTheme({
    palette: {
      type: "dark",
      primary: {
        main: "rgba(255, 255, 255, 1)",
      },
      text: {
        secondary: "rgba(70, 70, 70, 0.9)",
      },
      background: {
        default: "rgb(228,192,16)",
      },
    },
    customPalette: {
      background: "linear-gradient(0deg, rgb(251,112,154) 5%, rgb(228,192,16) 100%)",
      paper: "rgba(251,112,154)",
      paperOpaque: "rgb(228,192,16)",
    },
  }),
  street: createMuiTheme({
    palette: {
      type: "dark",
      primary: {
        main: "rgba(255, 255, 255, 1)",
      },
      text: {
        secondary: "rgba(70, 70, 70, 0.8)",
      },
      background: {
        default: "#B9D1EB",
      },
    },
    customPalette: {
      background: "linear-gradient(0deg, #F876DE 5%, #B9D1EB 100%)",
      paper: "#F876DE",
      paperClosed: "rgba(150, 150, 175, 0.6)",
      paperOpaque: "#B9D1EB",
    },
  }),
  town: createMuiTheme({
    palette: {
      type: "dark",
      background: {
        default: "rgb(0, 197, 171)",
      },
    },
    customPalette: {
      background: "linear-gradient(0deg, rgb(5, 109, 216) 5%, rgb(0, 197, 171) 100%)",
      paper: "rgba(5, 109, 216)",
      paperOpaque: "rgb(0, 197, 171)",
    },
  }),
  sports: createMuiTheme({
    palette: {
      type: "dark",
      background: {
        default: "rgb(217, 229, 76)",
      },
    },
    customPalette: {
      background: "linear-gradient(180deg, rgb(217, 229, 76) 5%, rgb(70, 154, 108) 100%)",
      paper: "rgb(70, 154, 108)",
      paperOpaque: "rgb(217, 229, 76)",
    },
  }),
  about: createMuiTheme({
    palette: {
      type: "dark",
      background: {
        default: "rgb(26, 26, 26)",
      },
    },
    customPalette: {
      background: "linear-gradient(0deg, rgb(50, 50, 50) 5%, rgb(26, 26, 26) 100%)",
      paper: "rgb(50, 50, 50)",
      paperOpaque: "rgb(26, 26, 26)",
    },
  }),
  events: createMuiTheme({
    palette: {
      type: "dark",
      background: {
        default: "rgb(255, 184, 140)",
      },
    },
    customPalette: {
      background: "linear-gradient(0deg,  rgb(222, 98, 98) 5%, rgb(255, 184, 140) 100%)",
      paper: "rgb(222, 98, 98)",
      paperOpaque: "rgb(255, 184, 140)",
    },
  }),
};

export default themes;
