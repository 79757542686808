import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Ad, AllSports, Business, EatingClub, Menu, TopStories, Event } from "../types";

export const campusApi = createApi({
  reducerPath: "campusApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/",
  }),
  endpoints: (builder) => ({
    getMenus: builder.query<Menu[], void>({
      query: () => "menus",
    }),
    getMainEntrees: builder.query<Menu[], void>({
      query: () => "main-entrees",
    }),
    getStreet: builder.query<EatingClub[], void>({
      query: () => "street",
    }),
    getTown: builder.query<Business[], void>({
      query: () => "town",
    }),
    getSports: builder.query<AllSports, void>({
      query: () => "sports",
    }),
    getAds: builder.query<Ad[], void>({
      query: () => "ads",
    }),
    getStreetAds: builder.query<Ad[], void>({
      query: () => "street-ads",
    }),
    getSportsAds: builder.query<Ad[], void>({
      query: () => "sports-ads",
    }),
    getTopStories: builder.query<TopStories, void>({
      query: () => "top-stories",
    }),
    getEvents: builder.query<Event[], void>({
      query: () => "events",
    }),
  }),
});

export const {
  useGetMenusQuery,
  useGetMainEntreesQuery,
  useGetStreetQuery,
  useGetTownQuery,
  useGetSportsQuery,
  useGetAdsQuery,
  useGetStreetAdsQuery,
  useGetSportsAdsQuery,
  useGetTopStoriesQuery,
  useGetEventsQuery,
} = campusApi;
