import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";

import appReducer from "../appSlice";
import featureDrawerReducer from "../features/feature-drawer/featureDrawerSlice";
import princeMenusReducer from "../features/menus/menusSlice";
import prospectAveReducer from "../features/street/streetSlice";
import aboutPageReducer from "../features/about/aboutSlice";
import aboutTermsReducer from "../features/about/termsSlice";
import contentCardReducer from "../components/content-card/contentCardSlice";
import adCardReducer from "../components/ad-card/adCardSlice";
import townPageReducer from "../features/town/townSlice";
import sportsReducer from "../features/sports/sportsSlice";

import { weatherApi } from "./services/weather";
import { campusApi } from "./services/campus";

export const store = configureStore({
  reducer: {
    app: appReducer,
    featureDrawer: featureDrawerReducer,
    princeMenus: princeMenusReducer,
    prospectAve: prospectAveReducer,
    aboutPage: aboutPageReducer,
    aboutTerms: aboutTermsReducer,
    contentCard: contentCardReducer,
    adCard: adCardReducer,
    townPage: townPageReducer,
    sports: sportsReducer,
    [weatherApi.reducerPath]: weatherApi.reducer,
    [campusApi.reducerPath]: campusApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(weatherApi.middleware).concat(campusApi.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
