import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CurrentWeather, CurrentWeatherQueryArgs, OneCallWeatherField, OneCallWeatherQueryArgs } from "../types";

export const weatherApi = createApi({
  reducerPath: "weatherApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://pro.openweathermap.org/data/2.5/",
  }),
  endpoints: (builder) => ({
    getOneCallWeather: builder.query<OneCallWeatherField, OneCallWeatherQueryArgs>({
      query: (args) =>
        `onecall?lat=${args.lat}&lon=${args.lon}&appid=${args.appid}&units=${args.units}&lang=${args.lang}`,
    }),
    getCurrentWeather: builder.query<CurrentWeather, CurrentWeatherQueryArgs>({
      query: (args) => `weather?id=${args.id}&appid=${args.appid}&units=${args.units}&lang=${args.lang}`,
    }),
  }),
});

export const { useGetOneCallWeatherQuery, useGetCurrentWeatherQuery } = weatherApi;
