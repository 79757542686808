export const DINING_HALLS = ["CJL", "Forbes", "Grad", "Ro / Ma", "Whitman", "Yeh / NCW"];

export const MEAL_HOURS: Record<string, string> = {
  breakfast: "7:30am - 11:00am",
  brunch: "10:00am - 2:00pm",
  lunch: "11:30am - 2:00pm",
  dinner: "5:00pm - 8:00pm",
};

export const MONTH_NAMES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

type MealPeriod = "breakfast" | "lunch" | "dinner";
export const MEALS: MealPeriod[] = ["breakfast", "lunch", "dinner"];

export const getDefaultActiveMeal = (): number => {
  const date = new Date();
  const hours = date.getHours();
  const isWeekend = date.getDay() === 6 || date.getDay() === 0;

  if (!isWeekend) {
    if (hours >= 21 || hours < 10) return 0;
    if (hours >= 10 && hours < 14) return 1;
    if (hours >= 14 && hours < 21) return 2;
  } else {
    if (hours >= 21 || hours < 14) return 0;
    if (hours >= 14 && hours < 21) return 1;
  }

  return -1;
};
