import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Terms = "terms" | "none";

export interface AboutTermState {
  terms: Terms;
}

const initialState: AboutTermState = {
  terms: "none",
};

export const termsSlice = createSlice({
  name: "aboutTerms",
  initialState,
  reducers: {
    changeTerms: (state: AboutTermState, action: PayloadAction<Terms>) => {
      console.log("changed");
      return {
        ...state,
        terms: action.payload,
      };
    },
  },
});

export const { changeTerms } = termsSlice.actions;
export default termsSlice.reducer;
