interface DrawerLabel {
  name: string;
  slug: "home" | "menus" | "street" | "town" | "sports" | "events" | "about";
}

const drawerLabels: DrawerLabel[] = [
  {
    name: "HOME",
    slug: "home",
  },
  {
    name: "MENUS",
    slug: "menus",
  },
  {
    name: "STREET",
    slug: "street",
  },
  {
    name: "TOWN",
    slug: "town",
  },
  {
    name: "SPORTS",
    slug: "sports",
  },
  {
    name: "EVENTS",
    slug: "events",
  },
  {
    name: "ABOUT",
    slug: "about",
  },
];

export default drawerLabels;
