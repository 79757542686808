export const EATING_CLUBS = [
  "Cannon",
  "Cap",
  "Charter",
  "Cloister",
  "Colonial",
  "Cottage",
  "Ivy",
  "Quad",
  "Terrace",
  "Tiger Inn",
  "Tower",
];
export const EATING_CLUB_EXCLUDES = [""];
export const DAY_NAMES = ["Thursday", "Friday", "Saturday", "Sunday"];
export const MONTH_NAMES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
export const STATUS_ORDER = ["Open", "List", "List Only", "Pass", "Members Only", "Closed", "Info Unavailable"];
export const WEEKEND_DAYS = [4, 5, 6, 0];

export const getDefaultActiveDay = (): number => {
  const date = new Date();
  if ([4, 5, 6].includes(date.getDay())) {
    return date.getDay() - 4;
  }

  if (date.getDay() === 0) {
    return 3;
  }

  return 0;
};

export const getNextDayOfWeek = (dayOfWeek: number): Date => {
  // https://codereview.stackexchange.com/questions/33527/find-next-occurring-friday-or-any-dayofweek

  const resultDate = new Date();

  resultDate.setDate(resultDate.getDate() + ((7 + dayOfWeek - resultDate.getDay()) % 7));

  return resultDate;
};
