import React from "react";

import { Theme } from "@material-ui/core/styles";

import { CircularProgress } from "@material-ui/core";
import { useAppSelector } from "../../app/hooks";

import themes from "../../config/themes";
import useStyles from "./style";

interface Props {
  includeTheme?: boolean; // eslint-disable-line react/require-default-props
}

const LoadingView: React.FC<Props> = ({ includeTheme = true }): JSX.Element => {
  const activeTheme = useAppSelector((state) => state.featureDrawer.activeTheme);
  const theme: Theme = themes[activeTheme];
  const classes = useStyles(theme);

  const conditionalStyle = includeTheme ? { background: theme.customPalette.background } : {};

  return (
    <main className={classes.main} style={conditionalStyle}>
      <CircularProgress classes={{ colorPrimary: classes.progressColor }} size={150} thickness={1.25} />
    </main>
  );
};

export default LoadingView;
