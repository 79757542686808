import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FeatureDrawerState {
  isOpen: boolean;
  pageLoaded: boolean;
  activePage: "home" | "menus" | "street" | "town" | "sports" | "about" | "events";
  activeTheme: "home" | "menus" | "street" | "town" | "sports" | "about" | "events";
  error: string;
}

const initialState: FeatureDrawerState = {
  isOpen: false,
  pageLoaded: false,
  activePage: "home",
  activeTheme: "home",
  error: "",
};

export const featureDrawerSlice = createSlice({
  name: "feature-drawer",
  initialState,
  reducers: {
    open: (state) => {
      return { ...state, isOpen: true };
    },
    close: (state) => {
      return { ...state, isOpen: false };
    },
    load: (state) => {
      return { ...state, pageLoaded: true };
    },
    setPage: (state, action: PayloadAction<FeatureDrawerState["activePage"]>) => {
      return { ...state, activePage: action.payload };
    },
    setTheme: (state, action: PayloadAction<FeatureDrawerState["activeTheme"]>) => {
      return { ...state, activeTheme: action.payload };
    },
    setError: (state, action: PayloadAction<string>) => {
      return { ...state, error: action.payload };
    },
  },
});

export const { open, close, load, setPage, setTheme, setError } = featureDrawerSlice.actions;
export default featureDrawerSlice.reducer;
