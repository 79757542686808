import React from "react";
import { Fab, List, ListItem, ListItemText, SwipeableDrawer, Theme, Link as AnchorLink, Grid } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { open, close, setPage, setTheme } from "./featureDrawerSlice";

import socialMedia from "./socialMedia";
import drawerLabels from "./drawerLabels";

import themes from "../../config/themes";
import useStyles from "./style";

export default function FeatureDrawer(): JSX.Element {
  const dispatch = useAppDispatch();

  const isOpen = useAppSelector((state) => state.featureDrawer.isOpen);
  // const activePage = useAppSelector((state) => state.featureDrawer.activePage);
  const activeTheme = useAppSelector((state) => state.featureDrawer.activeTheme);

  const theme: Theme = themes[activeTheme];
  const classes = useStyles();

  const sidebar = (
    <div
      className={classes.list}
      role="presentation"
      onClick={() => dispatch(close())}
      onKeyDown={() => dispatch(close())}
    >
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
        <div>
          <List style={{ display: "flex", flexDirection: "column" }}>
            {drawerLabels.map((item) => (
              <ListItem
                button
                component={RouterLink}
                to={`/${item.slug}`}
                key={item.slug}
                onClick={() => {
                  dispatch(setPage(item.slug));
                  dispatch(setTheme(item.slug));
                  // console.log(`navigating to ${item.slug}`);
                }}
              >
                <ListItemText primary={item.name} classes={{ primary: classes.listItemText }} />
              </ListItem>
            ))}
          </List>
        </div>
        <div>
          <List style={{ display: "flex", flexDirection: "column" }}>
            <ListItem
              button
              component={AnchorLink}
              href="https://dailyprincetonian.com"
              key="daily-princetonian"
              target="_blank"
              rel="noopener"
            >
              <ListItemText primary="The Daily Princetonian" classes={{ primary: classes.princeWordmark }} />
            </ListItem>
            <ListItem>
              <Grid container spacing={1} direction="row">
                {socialMedia.map((item) => (
                  <Grid
                    item
                    xs={2}
                    key={item.name}
                    component={AnchorLink}
                    href={item.url}
                    target="_blank"
                    rel="noopener"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <i className={item.icon} />
                  </Grid>
                ))}
              </Grid>
            </ListItem>
          </List>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {!isOpen && (
        <Fab
          className={classes.menuButton}
          style={{ background: theme.customPalette.paper }}
          onClick={() => dispatch(open())}
        >
          <Menu />
        </Fab>
      )}
      <SwipeableDrawer
        classes={{ paper: classes.paperBackground }}
        anchor="right"
        open={isOpen}
        onOpen={() => dispatch(open())}
        onClose={() => dispatch(close())}
      >
        {sidebar}
      </SwipeableDrawer>
    </div>
  );
}
